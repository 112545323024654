const applySkuProps = (customProps, skuParam) => {
  if (!skuParam || `${skuParam}` === "undefined" || skuParam.length !== 10) {
    return customProps;
  }

  const sku = skuParam.toUpperCase();

  let { themeId } = customProps;
  const skuKeyThemeId = sku.substring(0, 2);
  switch (skuKeyThemeId) {
    case "BK":
      themeId = "black";
      break;
    case "MB":
      themeId = "midnightblue";
      break;
    case "AQ":
      themeId = "aquamarine";
      break;
    case "GB":
      themeId = "grayblue";
      break;
    default:
      break;
  }

  const skuKeyWhite = sku.substring(2, 3);
  const white = skuKeyWhite === "W";
  if (white) {
    themeId = `${themeId}White`;
  }

  let isDigital = false;
  let isCanvas = false;
  let isFramed = false;
  let frameColor = "";

  const skuKeyFormat = sku.substring(3, 4);
  switch (skuKeyFormat) {
    case "P":
      // Print:
      isFramed = false;
      isCanvas = false;
      isDigital = false;
      break;
    case "N":
      // Natural Frame
      isFramed = true;
      frameColor = "natural";
      isCanvas = false;
      isDigital = false;
      break;
    case "B":
      // Black
      isFramed = true;
      frameColor = "black";
      isCanvas = false;
      isDigital = false;
      break;
    case "W":
      // White:
      isFramed = true;
      frameColor = "white";
      isCanvas = false;
      isDigital = false;
      break;
    case "C":
      // Canvas:
      isFramed = false;
      isCanvas = true;
      isDigital = false;
      break;
    case "D":
      // Canvas:
      isFramed = false;
      isCanvas = false;
      isDigital = true;
      break;
    default:
      break;
  }

  let { orientation } = customProps;
  const landscapePortraitSize = orientation;

  const skuKeyShape = sku.substring(4, 5);
  switch (skuKeyShape) {
    case "P":
      // Portrait
      orientation = "portrait";
      break;
    case "L":
      // Landscape
      orientation = "landscape";
      break;
    case "S":
      // Square
      orientation = "square";
      break;
    default:
      break;
  }

  const skuKeySizeId = sku.substring(5, 10).toLowerCase();

  const originalProps = {
    customProps: {
      ...customProps,
      themeId,
      white,
      isFramed,
      frameColor: isFramed ? frameColor : null,
      isCanvas,
      isDigital,
    },
  };

  const skuOverrides = {
    themeId,
    white,
    isFramed,
    frameColor: isFramed ? frameColor : null,
    isCanvas,
    isDigital,
    sizeId: skuKeySizeId,
    orientation,
    landscapePortraitSize,
    originalProps,
  };

  return {
    ...customProps,
    ...skuOverrides,
  };
};
export default {
  applySkuProps,
};
