/* eslint-disable react/no-unused-state */
// TODO: reset when navigating to landing, etc.
import React from "react";
import moment from "moment";
import queryString from "query-string";
import { OrreryPropsContextProvider } from "./ItemPropsContext";
import designApi from "../api/design";
import configApi from "../api/config";
import { NextI18NextInstance } from "../i18n";
import { getNewSize } from "../utils/size";
// import ConstellationLabels from "../utils/thenightsky-poster/libs/StarMap/ConstellationLabels";
// import dateUtils from "../utils/date";
import env from "../config/environment";
import ga4Analytics from "../utils/analytics/ga4Analytics";
import processProps from "../utils/customProps";

const { DEFAULT_PRODUCT_TYPE } = env;

class OrreryPropsProvider extends React.Component<{}, any> {
  constructor(props) {
    super(props);
    this.state = {
      configLoaded: false,
      customProps: {},
      layout: {},
      language: NextI18NextInstance.i18n.language,
      originalProps: {},
      config: {},
      layouts: {},
      themes: {},
      priceList: null,
      configError: ``,
      // TODO:
      previewImageUrl: undefined,
      mapLoaded: false,
      searchTerm: undefined,
      // TODO: do we need this?
      mapRef: null,
      step: 1,
      // selectedOrientation: null,
      selectedThemeId: null,
      selectedSizeId: null,
      selectedSizeLabel: ``,
      selectedSceneId: ``,
      landscapePortraitSize: ``,
      designEdited: 0,

      zoom: null,
      // orreryRef: {},
      showLegend: false,
      invertBorder: false,
      layoutPanel: null,
      orientSideways: false,
      showResetViewButton: false,
      fullscreenClicked: 0,
      firstDesktopStep: true,
      thirdMobileStep: false,
      fullscreen: false,
      urlLoadedSku: "",

      updateMapText: this.updateMapText,
      updateMapTextDate: this.updateMapTextDate,
      updateMapTextTitle: this.updateMapTextTitle,
      updateMapTime: this.updateMapTime,
      updateShape: this.updateShape,
      changeMapTheme: this.changeMapTheme,
      toggleOrientation: this.toggleOrientation,
      loadDefaultConfig: this.loadDefaultConfig,

      // update
      notifyDesignEdited: this.notifyDesignEdited,

      updateSelectedThemeId: this.updateSelectedThemeId,
      updateSelectedSceneId: this.updateSelectedSceneId,
      updateLayoutPanel: this.updateLayoutPanel,
      // updateSelectedSizeId: this.updateSelectedSizeId,
      // updateToPortrait: this.updateToPortrait,
      // updateToLandscape: this.updateToLandscape,
      updateOrientSideways: this.updateOrientSideways,
      updateResetViewButton: this.updateResetViewButton,
      // zoomIn: this.zoomIn,
      // zoomOut: this.zoomOut,
      updateOrreryInvertBorder: this.updateOrreryInvertBorder,
      updateOrreryColored: this.updateOrreryColored,
      updateOrreryPlanetLabels: this.updateOrreryPlanetLabels,
      updateOrreryDwarfPlanets: this.updateOrreryDwarfPlanets,
      updateOrreryPlanetsAndPluto: this.updateOrreryPlanetsAndPluto,
      updateOrreryShowLegend: this.updateOrreryShowLegend,
      // updateOrreryRenderAt: this.updateOrreryRenderAt,
      updateFullscreenClicked: this.updateFullscreenClicked,
      updateOrreryTextSubtitle: this.updateOrreryTextSubtitle,
      resetView: this.resetView,

      setToCartItem: this.setToCartItem,
      setFrame: this.setFrame,
      setCanvas: this.setCanvas,
      setDigital: this.setDigital,
      resetMapProps: this.resetMapProps,
      clearPropsContext: this.clearPropsContext,
      shouldGetCanvasImage: false,
      setShouldGetCanvasImage: this.setShouldGetCanvasImage,
      shouldSaveDesign: false,
      setShouldSaveDesign: this.setShouldSaveDesign,
      shouldDropAHint: false,
      setShouldDropAHint: this.setShouldDropAHint,
      setCanvasImageUrl: this.setCanvasImageUrl,
      setMapLoaded: this.setMapLoaded,
      // changeMapLayout: this.changeMapLayout,
      // toggleBorder: this.toggleBorder,
      updateSearchTerm: this.updateSearchTerm,
      setMapRef: this.setMapRef,
      setStep: this.setStep,
      updateSizeText: this.updateSizeText,
      updateSize: this.updateSize,
      setFirstDesktopStep: this.setFirstDesktopStep,
      // setThirdMobileStep: this.setThirdMobileStep,
      formatUpdated: this.formatUpdated,

      // set true when user moves from step 1 to 2 (and detected in TnsOrrery.jsx to stop the animating)
      stopAnimating: false,
      setStopAnimating: this.setStopAnimating,
    };
  }

  componentDidMount(): void {
    // Only init this provider if it's a TPA solar system
    if (DEFAULT_PRODUCT_TYPE !== "orrery") return;

    let countryCode = "";
    try {
      if (countryCode === "" && (window as any).location !== undefined && (window as any).location.search !== undefined) {
        const qs = queryString.parse((window as any).location.search);
        if (qs.tnscc && `${qs.tnscc}`.length >= 2) {
          countryCode = `${qs.tnscc}`;
        }
      }

      if (
        countryCode === "" &&
        window &&
        window.localStorage &&
        window.localStorage.getItem("tnsCountryCode") &&
        window.localStorage.getItem("tnsCountryCode") !== ""
      ) {
        countryCode = window.localStorage.getItem("tnsCountryCode") || "";
      }
    } catch {
      // ignore qs parsing issues
    }

    // Check for SKU:
    let loadSku = "";
    if ((window as any).location !== undefined && (window as any).location.search !== undefined) {
      const qs = queryString.parse((window as any).location.search);
      if (qs.sku && `${qs.sku}` !== "undefined" && `${qs.sku}`.length > 2) {
        loadSku = `${qs.sku}`;
        this.setState({
          urlLoadedSku: loadSku,
        });
      }
    }

    if (countryCode && countryCode !== "") {
      this.loadDefaultConfig(countryCode, loadSku);
    } else {
      // todo: we do this separately in OrderContext; also this.loadDefaultConfig(countryCode) could just lookup the
      // country itself and save a round-trip
      configApi.getUserCountryCode().then((detectedCountryCode) => {
        this.loadDefaultConfig(detectedCountryCode || "", loadSku);
      });
    }

    const promises = [designApi.getLayouts("orrery"), designApi.getThemes("orrery")];
    Promise.all(promises).then(async (values) => {
      const layouts = values[0];
      const themes = values[1];

      this.setState({
        themes,
        layouts,
      });
    });

    if (window && !window.localStorage.getItem("tnsLang")) {
      window.localStorage.setItem("tnsLang", NextI18NextInstance.i18n.language);
    }
  }

  loadDefaultConfig = async (countryCode: string, loadSku?: string): Promise<void> => {
    try {
      const themes = Object.keys(this.state.themes).length > 0 ? this.state.themes : await designApi.getThemes("orrery");
      const layouts = Object.keys(this.state.layouts).length > 0 ? this.state.layouts : await designApi.getLayouts("orrery");
      const defaultCountryConfig = await designApi.getDefaultConfig(countryCode, "orrery");
      if (!defaultCountryConfig) {
        this.setState({
          configError: `There was a problem loading the map.`,
          configLoaded: true,
        });
      }

      const { config } = defaultCountryConfig;
      let { customProps } = defaultCountryConfig;

      // if SKU Specified, load the props:
      if (loadSku && loadSku !== "") {
        customProps = processProps.applySkuProps(customProps, loadSku);
      }

      // Send "Default" config load events
      ga4Analytics.logEventDefaultTheme(customProps.themeId);
      ga4Analytics.logEventDefaultThemeOptionOnWhite(customProps.white);
      ga4Analytics.logEventDefaultThemeOptionConstellations(customProps.constellations);
      ga4Analytics.logEventDefaultThemeOptionGrid(customProps.grid);
      ga4Analytics.logEventDefaultThemeOptionMoon(customProps.moon);

      // Log Default Orientation
      // ga4Analytics.logEventDefaultDesignLayout(orientation);
      ga4Analytics.logEventDefaultDesignSize(customProps.sizeId);
      // ga4Analytics.logEventDefaultDesignFormat(isPendantPage ? "Pendant" : "Print");
      ga4Analytics.logEventDefaultDesignFormat("Print");

      const theme = themes[customProps.themeId];
      const layout = layouts[customProps.sizeId][customProps.orientation];
      const landscapePortraitSize = Object.values(getNewSize(countryCode))[0];
      this.setState(
        {
          customProps,
          theme,
          layout,
          config,
          originalProps: { customProps },
          landscapePortraitSize,
        },
        () => {
          this.setState({
            configError: "",
            configLoaded: true,
          });
        },
      );

      try {
        const priceList = await configApi.getCountryConfig("orrery", countryCode);
        this.setState({ priceList }, () => {
          this.updateSizeText();
        });
      } catch (error) {
        this.setState({ fetchAvailablePrices: error.message });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        configError: `There was a problem loading the map.`,
        configLoaded: true,
      });
    }
  };

  updateOrreryTextSubtitle = ({ textSubtitle }: { textSubtitle: string }): void => {
    const newText = textSubtitle;

    // const { orreryRef } = this.state;
    // const boundsCheck = orreryRef.current.isTextWithinBounds({
    //   newText,
    // });
    // if (!boundsCheck.textSubtitle.inBounds) {
    //   newText = boundsCheck.textSubtitle.croppedText;
    // }

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, textSubtitle: newText },
    }));
  };

  sizeIdIsSquare = (size: string): boolean | null => {
    if (!size) return null;

    const parts = size.toLowerCase().split("x");
    if (parts.length === 2) {
      if (parts[0] === parts[1]) {
        return true;
      }

      return false;
    }

    return false;
  };

  updateShape = (shapeParam: string): boolean => {
    // Orrery doesn't have aportrait size
    const shape = shapeParam === "portrait" ? "landscape" : shapeParam;

    // Set defaults
    const { originalProps } = this.state;
    const sizesForCountry = {
      landscape: originalProps.customProps.sizeId,
      // portrait and landscape share size ids
      portrait: originalProps.customProps.sizeId,
      square: "16x16",
    };

    // get current orientation/size
    const { customProps, landscapePortraitSize } = this.state;
    const { orientation, sizeId } = customProps;

    // do we need to switch?
    if (orientation === shape) {
      // no change - return
      return false;
    }

    // Current values, to overwrite
    let updateToSizeId;
    let updateToOrientation;
    switch (shape) {
      case "square": {
        // Change from landscape/portrait to square

        // Save State for if we revert out of square
        if (!this.sizeIdIsSquare(sizeId)) {
          this.setState({ landscapePortraitSize: `${sizeId}` });
        } else {
          this.setState({ landscapePortraitSize: sizesForCountry.landscape });
        }

        // switch to square
        updateToSizeId = sizesForCountry.square;
        updateToOrientation = shape; // = "square"
        break;
      }
      case "landscape": {
        // Change from portrait/square to landscape
        updateToOrientation = shape; // = "landscape"
        updateToSizeId = landscapePortraitSize || sizesForCountry.landscape;
        break;
      }
      default: {
        // shape unrecognised
        console.error(`Shape unrecognized: ${shape}`);
        return false;
      }
    }

    if (!updateToSizeId || !updateToOrientation) {
      return false;
    }

    ga4Analytics.logEventDesignLayout(updateToOrientation);
    ga4Analytics.logEventDesignSize(updateToSizeId);

    // Get layout and apply update to state
    const { layouts } = this.state;
    const layout = layouts[updateToSizeId][updateToOrientation];
    // eslint-disable-next-line no-shadow
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        sizeId: updateToSizeId,
        orientation: updateToOrientation,
      },
      selectedSizeId: updateToSizeId,
      layout,
      shouldGetCanvasImage: true,
    }));

    return true;
  };

  updateOrreryTheme = (): void => {
    const { selectedThemeId, themes } = this.state;
    const restructuredThemes = Object.entries(themes).map(([key, value]: any[]) => ({
      id: key,
      ...value,
    }));
    const theme = restructuredThemes.filter((t) => t.id === selectedThemeId)[0];
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, theme, themeId: theme.id },
    }));
  };

  updateSelectedThemeId = ({ themeId }: { themeId: string }): void => {
    this.setState({ selectedThemeId: themeId }, () => {
      this.updateOrreryTheme();
    });
  };

  updateLayoutPanel = (layoutPanel: string): void => {
    this.setState({ layoutPanel });
  };

  updateSelectedSceneId = (sceneId: string): void => {
    const { customProps } = this.state;
    const timestamp = new Date().getTime();

    let sceneProps = {};
    switch (sceneId) {
      case "middle": {
        sceneProps = {
          cx: 12.833724021236954,
          cy: -17.8797818781122,
          cz: 223.85310770133347,
          tx: 3.5623859607120227,
          ty: -3.8477212935163463,
          tz: 3.0010061687965923e-16,
          fov: 45,
          zoom: 0.85737,
          resetView: timestamp,
        };
        break;
      }
      case "right": {
        sceneProps = {
          cx: 31.93433502754606,
          cy: 172.38285137421556,
          cz: 47.008384444712185,
          tx: 14.596485486464957,
          ty: 50.010950414680764,
          tz: 8.533631900010583e-17,
          fov: 45,
          zoom: 0.82,

          resetView: timestamp,
        };
        break;
      }
      case "default":
      case "left": {
        sceneProps = {
          cx: 67.81559419002406,
          cy: -156.64095611306652,
          cz: 52.273342158670346,
          tx: 22.629297582429864,
          ty: -33.636115835209594,
          tz: 2.835838678925231e-16,
          fov: 45,
          zoom: 0.85737,
          resetView: timestamp,
        };
        break;
      }
      default: {
        console.error(`Scene must be "left", "right" or "middle"`);
        break;
      }
    }

    // this.updateResetViewButton(sceneProps);

    this.setState(() => ({
      customProps: {
        ...customProps,
        ...sceneProps,
        // resetView: timestamp,
      },
      selectedSceneId: sceneId,
    }));
  };

  updateOrreryInvertBorder = (invert: boolean): void => {
    const invertBorder = invert === true;
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, invertBorder },
      invertBorder,
    }));
  };

  updateOrreryColored = (coloredPlanets: boolean): void => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, colored: coloredPlanets },
      colored: coloredPlanets,
    }));
  };

  updateOrreryPlanetLabels = (planetLabels: boolean): void => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, planetLabels },
      planetLabels,
    }));
  };

  updateOrreryDwarfPlanets = (dwarfPlanets: boolean): void => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, dwarfPlanets },
      dwarfPlanets,
    }));
  };

  updateOrreryPlanetsAndPluto = (planetsAndPluto: boolean): void => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, planetsAndPluto },
      planetsAndPluto,
    }));
  };

  resetView = (): void => {
    const DEFAULT_CAMERA_PROPERTIES = {
      cx: 65.523,
      cy: -151.751,
      cz: 37.843,
      tx: 32.42,
      ty: -52.53,
      tz: 0,
      zoom: 0.85737,
    };

    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        ...DEFAULT_CAMERA_PROPERTIES,
        resetView: customProps.resetView + 1,
      },
      showResetViewButton: false,
    }));
  };

  updateFullscreenClicked = (): void => {
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        fullscreenClicked: customProps.fullscreenClicked ? customProps.fullscreenClicked + 1 : 1,
      },
    }));
  };

  updateResetViewButton = (cameraProps: any): void => {
    this.setState(({ customProps }) => ({
      showResetViewButton: true,
      customProps: {
        ...customProps,
        ...cameraProps,
      },
    }));
  };

  updateOrreryShowLegend = (showLegendParam: boolean): void => {
    const showLegend = showLegendParam === true;
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, showLegend },
      showLegend,
    }));
  };

  updateOrientSideways = (orient): void => {
    this.setState(() => ({
      orientSideways: orient,
    }));
  };

  clearMapContext = () => {
    this.setState({});
  };

  setStep = (step) => {
    if (step === 1) {
      this.setFirstDesktopStep(true);
    }
    this.setState({ step });
  };

  setCanvas = (canvas: boolean): void => {
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        isCanvas: canvas,
      },
      // update canvas image when canvas set
      shouldGetCanvasImage: true,
    }));
  };

  setFrame = (frame: boolean): void => {
    let framed = false;
    let frameColor;
    if (frame) {
      framed = true;
      frameColor = frame;
    }
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        isFramed: framed,
        frameColor,
      },
      // update canvas image when canvas set
      shouldGetCanvasImage: true,
    }));
  };

  setDigital = (digital: boolean): void => {
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        isDigital: digital,
      },
      // update canvas image when canvas set
      shouldGetCanvasImage: true,
    }));
  };

  formatUpdated = (format: string): void => {
    ga4Analytics.logEventDesignFormat(format);
  };

  updateMapText = (text: string): void => {
    const newText = text;

    // const { orreryRef } = this.state;
    // const boundsCheck = orreryRef.current.isTextWithinBounds({
    //   text,
    // });
    // if (!boundsCheck.text.inBounds) {
    //   newText = boundsCheck.text.croppedText;
    // }

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, text: newText },
    }));
  };

  // updateMapText = (mapText) => {
  //   const newText = mapText;

  //   this.setState(({ customProps }) => ({
  //     customProps: { ...customProps, text: newText },
  //   }));
  // };

  updateMapTextDate = (mapTextDate: string, isPendant: boolean): void => {
    const newText = mapTextDate;

    // Log Location Update
    ga4Analytics.logEventDesignDate(mapTextDate);

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, textDate: newText.toUpperCase() },
    }));
  };

  updateMapTextLocation = (mapTextLocation: string): void => {
    const newText = mapTextLocation;

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, textLocation: newText.toUpperCase(), textLocationPlaceholder: newText.toUpperCase() },
    }));
  };

  updateMapTextLocationPlaceholder = (mapTextLocation: string): void => {
    const newText = mapTextLocation;

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, textLocationPlaceholder: newText.toUpperCase() },
    }));
  };

  updateMapTextTitle = (mapTextTitle: string): void => {
    const newText = mapTextTitle;

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, textTitle: newText.toUpperCase() },
    }));
  };

  updateMapLongitude = (longitude: number): void => {
    const { customProps: props } = this.state;
    ga4Analytics.logEventDesignCoordinatesText(props.latitude, longitude);

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, longitude },
    }));
  };

  updateMapLatitude = (latitude: number): void => {
    const { customProps: props } = this.state;
    ga4Analytics.logEventDesignCoordinatesText(latitude, props.longitude);

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, latitude },
    }));
  };

  updateOrreryRenderAt = (renderAt: Date): void => {
    this.setState(({ customProps: props }) => {
      return {
        customProps: {
          ...props,
          renderAt,
        },
      };
    });
  };

  getLocaleText = (l) => {
    switch (l) {
      case "en":
        return "AT";
      case "de":
        return "UM";
      case "es":
        return "A LAS";
      case "pt":
        return "ÀS";
      case "it":
        return "ALLE";
      case "fr":
        return "À";
      case "nl":
        return "OM";
      case "pl":
        return "O";
      default:
        return "AT";
    }
  };

  updateMapTime = (timeParam: any, isPendant: boolean): void => {
    const { customProps } = this.state;
    const time = timeParam;

    // Store
    if (time.month === 2 && time.date > 28) {
      if ((time.year % 4 === 0 && time.year % 100 !== 0) || time.year % 400 === 0) {
        time.date = 29;
      } else {
        time.date = 28;
      }
    }

    if (time.date > 30 && [4, 6, 9, 11].indexOf(time.month) >= 0) {
      time.date = 30;
    }

    this.setState(({ customProps: props }) => ({
      customProps: { ...props, time },
    }));
    moment.locale(NextI18NextInstance.i18n.language);

    const newMonth = `0${time.month}`.slice(-2);
    const newDate = `0${time.date}`.slice(-2);
    const newHour = `0${time.hour}`.slice(-2);
    const newMinute = `0${time.minute}`.slice(-2);
    const constructDate = `${time.year}-${newMonth}-${newDate}T${newHour}:${newMinute}:00Z`;

    const textHour = moment.utc(constructDate).format(`[${this.getLocaleText(moment.locale())}] h[:]mm`);
    ga4Analytics.logEventDesignTime(`${textHour} ${time.hour >= 12 ? `PM` : `AM`}`);

    if (isPendant) {
      const textDate = moment.utc(constructDate).format("MMMM Do, YYYY");
      if (customProps.showTime) {
        this.updateMapTextDate(`${textDate} ${textHour} ${time.hour >= 12 ? `PM` : `AM`}`, isPendant);
      } else {
        this.updateMapTextDate(textDate, isPendant);
      }
    } else {
      const textDateDay = moment.utc(constructDate).format("Do");
      const textDateMonth = moment.utc(constructDate).format("MMMM YYYY").toUpperCase();
      const textDate = `${textDateDay} ${textDateMonth}`;
      if (customProps.showTime) {
        this.updateMapTextDate(`${textDate} ${textHour} ${time.hour >= 12 ? `PM` : `AM`}`, isPendant);
      } else {
        this.updateMapTextDate(textDate, isPendant);
      }
    }

    // update render at date/time
    this.updateOrreryRenderAt(moment.utc(constructDate).toDate());
  };

  resetMapProps = (): void => {
    this.setState(({ originalProps, layouts, themes }) => ({
      customProps: originalProps.customProps,
      layout: layouts[originalProps.customProps.sizeId][originalProps.customProps.orientation],
      theme: themes[originalProps.customProps.themeId],
      previewImageUrl: undefined,
      mapLoaded: false,
      searchTerm: undefined,
      designEdited: 0,
    }));
  };

  clearPropsContext = (): void => {
    // Clear state
    this.setFirstDesktopStep(true);
    this.setStep(1);
    this.resetMapProps();
  };

  changeMapTheme = (themeId: string): any => {
    const { themes, customProps: props } = this.state;
    let updatedThemeId;

    if (props.white) {
      updatedThemeId = `${themeId}White`;
    } else {
      updatedThemeId = themeId;
    }

    const theme = themes[updatedThemeId];

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, themeId: updatedThemeId },
      theme,
    }));
  };

  updateSearchTerm = (val): void => {
    this.setState({
      searchTerm: val,
    });
  };

  notifyDesignEdited = () => {
    this.setState(({ designEdited }) => ({
      designEdited: designEdited + 1,
    }));
  };

  setToCartItem = ({ customProps, previewImageUrl, isCanvas, isFramed, isDigital, isPendant, frameColor }): void => {
    this.setState(({ layouts, themes }) => ({
      customProps,
      previewImageUrl,
      isCanvas,
      isFramed,
      isDigital,
      isPendant,
      frameColor,
      layout: layouts[customProps.sizeId][customProps.orientation],
      theme: themes[customProps.themeId],
      mapLoaded: false,
      stopAnimating: true, // stop animating (here in particular to stop animating edits)
    }));
  };

  setShouldGetCanvasImage = (value): void => {
    this.setState({
      shouldGetCanvasImage: value,
    });
  };

  setShouldSaveDesign = (value: string): void => {
    this.setState({
      shouldSaveDesign: value,
    });
  };

  setShouldDropAHint = (value: string): void => {
    this.setState({
      shouldDropAHint: value,
    });
  };

  setCanvasImageUrl = (previewImageUrl: string | null): void => {
    this.setState(() => ({
      previewImageUrl,
    }));
  };

  setMapLoaded = (value: boolean): void => {
    this.setState({
      mapLoaded: value,
    });
  };

  setMapRef = (map): void => {
    this.setState({
      mapRef: map,
    });
  };

  toggleOrientation = () => {
    const { customProps, layouts } = this.state;
    const { orientation, sizeId } = customProps;
    const newOrientation = orientation === `portrait` ? `landscape` : `portrait`;
    const layout = layouts[sizeId][newOrientation];

    this.setState(({ customProps: props }) => ({
      customProps: { ...props, orientation: newOrientation },
      layout,
    }));
  };

  toggleConstellations = () => {
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        constellations: !customProps.constellations,
      },
    }));
  };

  toggleGrid = () => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, grid: !customProps.grid },
    }));
  };

  toggleMoon = () => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, moon: !customProps.moon },
    }));
  };

  toggleShowTime = () => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, showTime: !customProps.showTime },
    }));
  };

  toggleWhite = () => {
    const { customProps } = this.state;
    const oldWhite = customProps.white;

    let _themeId;

    if (oldWhite) {
      _themeId = customProps.themeId.replace(`White`, ``);
    } else {
      _themeId = customProps.themeId;
    }

    this.setState(
      {
        customProps: { ...customProps, white: !oldWhite },
      },
      () => {
        this.changeMapTheme(_themeId);
      },
    );
  };

  updateSizeText = () => {
    // Update map size:
    const { priceList, customProps } = this.state;
    const { orientation, sizeId } = customProps;

    const sizeText = sizeId;

    // Make sure a valid text size is available
    if (
      !priceList ||
      !priceList.pricing ||
      !priceList.pricing[orientation] ||
      !priceList.pricing[orientation][sizeId] ||
      priceList.pricing[orientation][sizeId].sizeText === ""
    ) {
      // try to build from size id
      if (!sizeId || sizeId === "") {
        return this.setState({ sizeText });
      }

      const parts = sizeId.toLowerCase().split(`x`);
      const xSize = parts[0].trim();
      const ySize = parts[1].trim();

      if (orientation === "landscape") {
        return this.setState({ sizeText: `${ySize} x ${xSize}"` });
      }

      return this.setState({ sizeText: `${xSize} x ${ySize}"` });
    }

    return this.setState({ sizeText: priceList.pricing[orientation][sizeId].sizeText });
  };

  updateSize = (sizeId: string) => {
    // Log Location Update
    ga4Analytics.logEventDesignSize(sizeId);

    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        sizeId,
      },
      selectedSizeId: sizeId,
    }));
  };

  setFirstDesktopStep = (firstDesktopStep) => {
    this.setState({ firstDesktopStep });
  };

  setStopAnimating = (stopAnimating: boolean): void => {
    this.setState(() => ({ stopAnimating }));
  };

  render() {
    return (
      <OrreryPropsContextProvider value={this.state} {...this.props}>
        {this.props.children}
      </OrreryPropsContextProvider>
    );
  }
}

export default OrreryPropsProvider;
