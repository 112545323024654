/**
 * Get Formatted Price
 */
const getFormattedPrice = (currencyParam: string, n: number, countryCode?: string): string => {
  const currency = !currencyParam || currencyParam.trim() === "" ? "USD" : currencyParam.toUpperCase();

  let bcp47 = "";
  switch (currency) {
    case "USD":
      bcp47 = "en-US";
      break;
    case "EUR":
      bcp47 = "en-IE";
      break;
    case "GBP":
      bcp47 = "en-GB";
      break;
    case "AUD":
      bcp47 = "en-AU";
      break;
    default:
      bcp47 = "en-US";
      break;
  }

  // Set Currency Postfix
  let currencyPostfix = "";
  if (countryCode && countryCode !== undefined && countryCode !== "") {
    if (currency === "USD") {
      if (countryCode.substring(0, 2) !== "US") {
        currencyPostfix = " USD";
      }
    } else if (currency === "AUD") {
      if (countryCode === "NZ") {
        currencyPostfix = " AUD";
      }
    }
  } else {
    switch (currency) {
      case "USD":
        currencyPostfix = " USD";
        break;
      case "AUD":
        currencyPostfix = " AUD";
        break;
      default:
        break;
    }
  }

  return `${new Intl.NumberFormat(bcp47, { style: "currency", currency }).format(n / 100)}${currencyPostfix}`;
};

const formatMoney = (value: number, currency: string, showCentsParam?: boolean): string => {
  const amount = Math.floor(value) / 100;
  let showCents = true;
  if (showCentsParam === true) {
    showCents = true;
  } else if (showCentsParam === false) {
    showCents = false;
  } else {
    showCents = Math.floor(value) % 100 !== 0;
  }

  switch (currency.toLowerCase()) {
    case "eur":
      return `€${amount.toFixed(showCents ? 2 : 0)}`;
    case "gbp":
      return `£${amount.toFixed(showCents ? 2 : 0)}`;
    case "aud":
      return `$${amount.toFixed(showCents ? 2 : 0)}`;
    case "usd":
    default:
      return `$${amount.toFixed(showCents ? 2 : 0)}`;
  }
};

export default {
  getFormattedPrice,
  formatMoney,
};
