import axios from "axios";
import env from "../config/environment";

const { TNS_PLATFORM_API } = env;

const getDefaultConfig = async (countryCode: string, typeParam?: string) => {
  const d = { countryCode };
  const type = typeParam || "starmap";

  try {
    const resp = await axios.post(`${TNS_PLATFORM_API}/api/config/${type}`, d);
    return resp.data;
  } catch (err) {
    console.error(err.response ? err.response.data : "Something went wrong");
    throw err;
  }
};

const getLayouts = async (typeParam?: string) => {
  try {
    const type = typeParam || "starmap";
    const resp = await axios.get(`${TNS_PLATFORM_API}/api/config/${type}/layouts`);
    return resp.data;
  } catch (err) {
    console.error(err.response ? err.response.data : "Something went wrong");
    throw err;
  }
};

const getThemes = async (typeParam?: string) => {
  try {
    const type = typeParam || "starmap";
    const resp = await axios.get(`${TNS_PLATFORM_API}/api/config/${type}/themes`);
    return resp.data;
  } catch (err) {
    console.error(err.response ? err.response.data : "Something went wrong");
    throw err;
  }
};

export default {
  getDefaultConfig,
  getLayouts,
  getThemes,
};
