// This import is to try fix the weird next Link issue with css.
// c.f. https://github.com/zeit/next-plugins/issues/282
import "../public/empty.css";
import Router from "next/router";
import Head from "next/head";
import App from "next/app";
// import * as Sentry from "@sentry/browser";
// import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import env from "../config/environment";
import TnsStarmapPropsProvider from "../context/TnsStarmapPropsProvider";
import OrreryPropsProvider from "../context/OrreryPropsProvider";
import { TrackingDataProvider } from "../context/TrackingDataContext";
import { OrderProvider } from "../context/OrderContext";
import { CartProvider } from "../context/CartContext";
import { appWithTranslation } from "../i18n";
import Fonts from "../resources/fonts";

const { brandName } = env;
// const { brandName, SENTRY_DSN, LOG_LIMIT_SESSIONS } = env;

// hacky way to throttle Log Rocket (we have 25k sessions/month): we only track for the a few minutes each
// hour, every 4th hour
// const now = new Date();
// let logEnabled = false;
// if (LOG_LIMIT_SESSIONS && now.getUTCMinutes() < 5 && now.getUTCHours() % 4 === 0) {
//   logEnabled = false;
// }

// if (logEnabled && SENTRY_DSN && SENTRY_DSN !== "") {
//   Sentry.init({
//     dsn: SENTRY_DSN,
//     integrations: [
//       new CaptureConsoleIntegration({
//         levels: ["warn", "error", "debug"],
//       }),
//     ],
//   });
// }

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  componentDidMount() {
    Router.events.on(`routeChangeComplete`, () => {
      // ScrollTo
      try {
        window.scroll({ top: 0, left: 0 });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    });

    Fonts();
  }

  componentDidCatch(error, errorInfo) {
    // Sentry.withScope((scope) => {
    //   Object.keys(errorInfo).forEach((key) => {
    //     scope.setExtra(key, errorInfo[key]);
    //   });

    //   Sentry.captureException(error);
    // });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <title>{`${brandName}`}</title>
          <meta name="viewport" content="width=device-width, shrink-to-fit=no, user-scalable=yes, initial-scale=1" />
        </Head>

        <TrackingDataProvider>
          <OrderProvider>
            <OrreryPropsProvider>
              <TnsStarmapPropsProvider>
                <CartProvider>
                  <Component {...pageProps} />
                </CartProvider>
              </TnsStarmapPropsProvider>
            </OrreryPropsProvider>
          </OrderProvider>
        </TrackingDataProvider>
      </>
    );
  }
}

export default appWithTranslation(MyApp);
