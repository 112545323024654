/* eslint-disable react/no-unused-state */
// TODO: reset when navigating to landing, etc.
import React from "react";
import moment from "moment";
import queryString from "query-string";
import { TnsPrintPropsContextProvider } from "./ItemPropsContext";
import designApi from "../api/design";
import configApi from "../api/config";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/pt";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/pl";
import { NextI18NextInstance } from "../i18n";
import env from "../config/environment";
import processProps from "../utils/customProps";
import ga4Analytics from "../utils/analytics/ga4Analytics";
import { sizeIdIsSquare } from "../utils/utils";
import pricingUtils from "../utils/pricing";

const { BLACKLISTED_COUNTRIES, ACTIVE_SITE, DEFAULT_PRODUCT_TYPE } = env;

const isNightSkyMaps = ACTIVE_SITE === "nightskymaps";

class TnsStarmapPropsProvider extends React.Component<{}, any> {
  intervalId;

  constructor(props) {
    super(props);
    this.state = {
      configLoaded: false,
      configError: ``,
      customProps: {},
      layout: {},
      language: NextI18NextInstance.i18n.language,
      originalProps: {},
      config: {},
      layouts: {},
      themes: {},
      priceList: null,
      // TODO:
      previewImageUrl: undefined,
      shortLocationText: undefined,
      mapLoaded: false,
      searchTerm: undefined,
      // TODO: do we need this?
      mapRef: null,
      step: 1,
      firstDesktopStep: true,
      testingVariant: 0,
      landscapePortraitSize: "",
      designEdited: 0,
      urlLoadedSku: "",

      textSizeOk: true,
      urlCode: "",

      pendantMaterial: "silver",

      updateMapText: this.updateMapText,
      updateMapLocation: this.updateMapLocation,
      updateTextFromShortLocation: this.updateTextFromShortLocation,
      setShortLocationText: this.setShortLocationText,
      updateMapTextDate: this.updateMapTextDate,
      updateMapTextLocation: this.updateMapTextLocation,
      updateMapTextLocationPlaceholder: this.updateMapTextLocationPlaceholder,
      updateMapTextTitle: this.updateMapTextTitle,
      updateMapLongitude: this.updateMapLongitude,
      updateMapLatitude: this.updateMapLatitude,
      changeMapTheme: this.changeMapTheme,
      updateMapTime: this.updateMapTime,
      updateShape: this.updateShape,
      updatePendantMaterial: this.updatePendantMaterial,
      setFrame: this.setFrame,
      setCanvas: this.setCanvas,
      setDigital: this.setDigital,
      setPendant: this.setPendant,

      formatUpdated: this.formatUpdated,

      toggleConstellations: this.toggleConstellations,
      toggleGrid: this.toggleGrid,
      toggleLabels: this.toggleLabels,
      toggleMoon: this.toggleMoon,
      toggleShowTime: this.toggleShowTime,
      toggleWhite: this.toggleWhite,
      loadDefaultConfig: this.loadDefaultConfig,

      // update
      notifyDesignEdited: this.notifyDesignEdited,

      setToCartItem: this.setToCartItem,
      resetMapProps: this.resetMapProps,
      clearPropsContext: this.clearPropsContext,
      shouldGetCanvasImage: false,
      setShouldGetCanvasImage: this.setShouldGetCanvasImage,
      shouldSaveDesign: false,
      setShouldSaveDesign: this.setShouldSaveDesign,
      shouldDropAHint: false,
      setShouldDropAHint: this.setShouldDropAHint,
      setCanvasImageUrl: this.setCanvasImageUrl,
      setMapLoaded: this.setMapLoaded,
      updateSearchTerm: this.updateSearchTerm,
      setMapRef: this.setMapRef,
      setStep: this.setStep,
      setFirstDesktopStep: this.setFirstDesktopStep,
      setLocationSelected: this.setLocationSelected,
      updateSizeText: this.updateSizeText,
      updateSize: this.updateSize,
      setPendantImageCarouselSelected: this.setPendantImageCarouselSelected,
      setTextSizeOk: this.setTextSizeOk,
      cheapestPrintPriceForUpsell: this.cheapestPrintPriceForUpsell,
    };
  }

  componentDidMount() {
    // Only init this provider if it's a starmap/pendant
    if (["starmap"].indexOf(DEFAULT_PRODUCT_TYPE) < 0) return;

    // Push event for A/B test on the default color selection
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({ event: "optimize.activate" });
    }

    this.intervalId = setInterval(() => {
      if ((window as any).google_optimize !== undefined) {
        const variant = (window as any).google_optimize.get("yuu9V1DBTRi0gbY77MzB2Q");
        this.setState({ testingVariant: variant });
        clearInterval(this.intervalId);
      }
    }, 100);

    let countryCode = "";
    try {
      if (countryCode === "" && (window as any).location !== undefined && (window as any).location.search !== undefined) {
        const qs = queryString.parse((window as any).location.search);
        if (qs.tnscc && `${qs.tnscc}`.length >= 2) {
          countryCode = `${qs.tnscc}`;
        }
      }

      if (
        countryCode === "" &&
        window &&
        window.localStorage &&
        window.localStorage.getItem("tnsCountryCode") &&
        window.localStorage.getItem("tnsCountryCode") !== ""
      ) {
        countryCode = window.localStorage.getItem("tnsCountryCode") || "";
      }
    } catch {
      // ignore qs parsing issues
    }

    // Check for SKU, Discount Code
    let loadSku = "";
    let urlCode = "";
    if ((window as any).location !== undefined && (window as any).location.search !== undefined) {
      const qs = queryString.parse((window as any).location.search);
      if (qs.sku && `${qs.sku}` !== "undefined" && `${qs.sku}`.length > 2) {
        loadSku = `${qs.sku}`;
        this.setState({
          urlLoadedSku: loadSku,
        });
      }

      if (qs.code && `${qs.code}` !== "undefined" && `${qs.code}`.length > 2) {
        urlCode = `${qs.code}`;
        this.setState({
          urlCode,
        });
      }
    }

    if (countryCode && countryCode !== "") {
      this.loadDefaultConfig(countryCode, loadSku);
    } else {
      // todo: we do this separately in OrderContext; also this.loadDefaultConfig(countryCode) could just lookup the
      // country itself and save a round-trip
      configApi.getUserCountryCode().then((detectedCountryCode) => {
        this.loadDefaultConfig(detectedCountryCode || "", loadSku);
      });
    }

    const promises = [designApi.getLayouts(), designApi.getThemes()];
    Promise.all(promises).then(async (values) => {
      const layouts = values[0];
      const themes = values[1];

      this.setState({
        themes,
        layouts,
      });
    });

    if (window && !window.localStorage.getItem("tnsLang")) {
      window.localStorage.setItem("tnsLang", NextI18NextInstance.i18n.language);
    }
  }

  loadDefaultConfig = async (countryCode: string, loadSku?: string): Promise<void> => {
    try {
      const isPendantPage = window && window.location && window.location.href.indexOf(`pendant`) >= 0;

      // Temporary:
      if (countryCode && countryCode !== "" && BLACKLISTED_COUNTRIES.indexOf(countryCode) >= 0) {
        if (
          window.location.host &&
          window.location.hostname.indexOf("://create.") >= 0 &&
          window.location.hostname.indexOf("://digital.") < 0
        ) {
          window.location.href = window.location.hostname.replace("://create.", "://digital.");
          return;
        }
      }

      const { themes: localThemes, layouts: localLayouts } = this.state;
      const themes = Object.keys(localThemes).length > 0 ? localThemes : await designApi.getThemes();
      const layouts = Object.keys(localLayouts).length > 0 ? localLayouts : await designApi.getLayouts();
      const defaultCountryConfig: any = await designApi.getDefaultConfig(countryCode);
      if (!defaultCountryConfig) {
        this.setState({
          configError: `There was a problem loading the map.`,
          configLoaded: true,
        });
      }

      let { customProps } = defaultCountryConfig;

      // if SKU Specified, load the props:
      if (loadSku && loadSku !== "") {
        customProps = processProps.applySkuProps(customProps, loadSku);
      }

      if (customProps.textTitle === "") {
        customProps.textTitle = "THE NIGHT SKY";
        if (isNightSkyMaps) {
          customProps.textTitle = "NIGHT SKY MAPS";
        } else if (isPendantPage) {
          customProps.textTitle = "The Night Sky";
        }
      }

      // Send "Default" config load events
      ga4Analytics.logEventDefaultTheme(customProps.themeId);
      ga4Analytics.logEventDefaultThemeOptionOnWhite(customProps.white);
      ga4Analytics.logEventDefaultThemeOptionConstellations(customProps.constellations);
      ga4Analytics.logEventDefaultThemeOptionGrid(customProps.grid);
      ga4Analytics.logEventDefaultThemeOptionMoon(customProps.moon);

      let orientation = "Portrait";
      if (customProps.orientation === "square" || sizeIdIsSquare(customProps.sizeId)) {
        orientation = "Square";
      } else if (customProps.orientation === "portrait") {
        orientation = "Portrait";
      } else if (customProps.orientation === "landscape") {
        orientation = "Landscape";
      } else {
        orientation = customProps.orientation;
      }

      // Log Default Orientation
      ga4Analytics.logEventDefaultDesignLayout(orientation);
      ga4Analytics.logEventDefaultDesignSize(customProps.sizeId);
      ga4Analytics.logEventDefaultDesignFormat(isPendantPage ? "Pendant" : "Print");

      const theme = themes[customProps.themeId];
      const layout = layouts[customProps.sizeId][customProps.orientation];
      this.setState(
        {
          customProps,
          theme,
          layout,
          originalProps: { customProps },
        },
        () => {
          this.setState({
            configError: "",
            configLoaded: true,
          });
        },
      );

      // // Using this to escape race condition on themeId change from G-Optimize
      // if (this.state.testingVariant === 1) {
      //   const newProps = { ...this.state.customProps, themeId: "black" };
      //   this.setState({
      //     customProps: newProps,
      //     originalProps: { newProps },
      //   });
      // }

      try {
        const priceList = await configApi.getCountryConfig("starmap", countryCode);
        this.setState({ priceList }, () => {
          this.updateSizeText();
        });
      } catch (error) {
        this.setState({ fetchAvailablePrices: error.message });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        configError: `There was a problem loading the map.`,
        configLoaded: true,
      });
    }
  };

  clearMapContext = () => {
    this.setState({});
  };

  setStep = (step: number): void => {
    if (step === 1) {
      this.setFirstDesktopStep(true);
    }
    this.setState({ step });
  };

  setFirstDesktopStep = (firstDesktopStep: boolean): void => {
    this.setState({ firstDesktopStep });
  };

  setPendantImageCarouselSelected = (showPanel: number): void => {
    this.setState(({ pendantImageCarouselSelected }) => ({
      pendantImageCarouselSelected: showPanel,
    }));
  };

  setTextSizeOk = (textSizeOk: boolean): void => {
    this.setState({ textSizeOk });
  };

  setLocationSelected = (isLocationSelected: boolean): void => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, isLocationSelected },
    }));
  };

  updateMapText = (mapText: string): void => {
    const newText = mapText;

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, text: newText },
      // customProps: { ...customProps, text: newText.toUpperCase() },
    }));

    // Log Location Update
    ga4Analytics.logEventDesignText(mapText);
  };

  updateMapTextDate = (mapTextDate: string, isPendant: boolean): void => {
    const newText = mapTextDate;

    // Log Location Update
    ga4Analytics.logEventDesignDate(mapTextDate);

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, textDate: newText },
    }));
  };

  updateMapTextLocation = (mapTextLocation: string): void => {
    const newText = mapTextLocation;

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, textLocation: newText.toUpperCase(), textLocationPlaceholder: newText.toUpperCase() },
    }));
  };

  updateMapTextLocationPlaceholder = (mapTextLocation: string): void => {
    const newText = mapTextLocation;

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, textLocationPlaceholder: newText.toUpperCase() },
    }));
  };

  updateMapTextTitle = (mapTextTitle: string): void => {
    const newText = mapTextTitle;

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, textTitle: newText.toUpperCase() },
    }));
  };

  updateMapLongitude = (longitude: number): void => {
    const { customProps: props } = this.state;
    ga4Analytics.logEventDesignCoordinatesText(props.latitude, longitude);

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, longitude, isLocationSelected: true },
    }));
  };

  updateMapLatitude = (latitude: number): void => {
    const { customProps: props } = this.state;
    ga4Analytics.logEventDesignCoordinatesText(latitude, props.longitude);

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, latitude, isLocationSelected: true },
    }));
  };

  updateMapLocation = (mapTextLocation: string, latitude: number, longitude: number, isLocationSelected: boolean): void => {
    const newLocationText = mapTextLocation;

    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        textLocation: newLocationText,
        textLocationPlaceholder: newLocationText.toUpperCase(),
        longitude,
        latitude,
        isLocationSelected,
      },
    }));
  };

  updateTextFromShortLocation = (): void => {
    const { customProps: props } = this.state;
    const textTitle = props.textTitle || "The Night Sky";
    const { shortLocationText } = this.state;
    const updatedText = [textTitle, shortLocationText || null].join(`\n`);
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        text: updatedText,
      },
    }));
  };

  getLocaleText = (l: string): string => {
    switch (l) {
      case "en":
        return "AT";
      case "de":
        return "UM";
      case "es":
        return "A LAS";
      case "pt":
        return "ÀS";
      case "it":
        return "ALLE";
      case "fr":
        return "À";
      case "nl":
        return "OM";
      case "pl":
        return "O";
      default:
        return "AT";
    }
  };

  updateMapTime = (timeParam: any, isPendant: boolean): void => {
    const { customProps } = this.state;
    const time = timeParam;

    // Store
    if (time.month === 2 && time.date > 28) {
      if ((time.year % 4 === 0 && time.year % 100 !== 0) || time.year % 400 === 0) {
        time.date = 29;
      } else {
        time.date = 28;
      }
    }

    if (time.date > 30 && [4, 6, 9, 11].indexOf(time.month) >= 0) {
      time.date = 30;
    }

    this.setState(({ customProps: props }) => ({
      customProps: { ...props, time },
    }));
    moment.locale(NextI18NextInstance.i18n.language);

    const newMonth = `0${time.month}`.slice(-2);
    const newDate = `0${time.date}`.slice(-2);
    const newHour = `0${time.hour}`.slice(-2);
    const newMinute = `0${time.minute}`.slice(-2);
    const constructDate = `${time.year}-${newMonth}-${newDate}T${newHour}:${newMinute}:00Z`;

    const textHour = moment.utc(constructDate).format(`[${this.getLocaleText(moment.locale())}] h[:]mm`);
    ga4Analytics.logEventDesignTime(`${textHour} ${time.hour >= 12 ? `PM` : `AM`}`);

    if (isPendant) {
      const textDate = moment.utc(constructDate).format("MMMM Do, YYYY");
      if (customProps.showTime) {
        this.updateMapTextDate(`${textDate} ${textHour} ${time.hour >= 12 ? `PM` : `AM`}`, isPendant);
      } else {
        this.updateMapTextDate(textDate, isPendant);
      }
    } else {
      const textDateDay = moment.utc(constructDate).format("Do");
      const textDateMonth = moment.utc(constructDate).format("MMMM YYYY").toUpperCase();
      const textDate = `${textDateDay} ${textDateMonth}`;
      if (customProps.showTime) {
        this.updateMapTextDate(`${textDate} ${textHour} ${time.hour >= 12 ? `PM` : `AM`}`, isPendant);
      } else {
        this.updateMapTextDate(textDate, isPendant);
      }
    }
  };

  resetMapProps = (): void => {
    const { originalProps, layouts, themes } = this.state;
    this.setState(() => ({
      customProps: originalProps.customProps,
      layout: layouts[originalProps.customProps.sizeId][originalProps.customProps.orientation],
      theme: themes[originalProps.customProps.themeId],
      previewImageUrl: undefined,
      shortLocationText: undefined,
      mapLoaded: false,
      searchTerm: undefined,
      designEdited: 0,
    }));
  };

  clearPropsContext = (): void => {
    // Clear state
    this.setFirstDesktopStep(true);
    this.setStep(1);
    this.resetMapProps();
  };

  changeMapTheme = (themeId: string): void => {
    const { themes, customProps: props } = this.state;

    // Log update
    ga4Analytics.logEventTheme(themeId);

    const updatedThemeId = props.white ? `${themeId}White` : themeId;
    const theme = themes[updatedThemeId];
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, themeId: updatedThemeId },
      theme,
    }));
  };

  updateSearchTerm = (val: string): void => {
    this.setState({
      searchTerm: val,
    });
  };

  /**
   * the "previewImageUrl" here doesn't mean that the type of print is a canvas (as in print/framed/canvas) - it's the
   * base64 image data passed fromt he editor to cart so that thet thumbnail image is immediately visible
   */
  setToCartItem = ({ customProps, previewImageUrl }: { customProps: any; previewImageUrl: string }): void => {
    this.setState(({ layouts, themes }) => ({
      customProps,
      previewImageUrl,
      isCanvas: customProps.isCanvas,
      isFramed: customProps.isFramed,
      frameColor: customProps.frameType,
      isDigital: customProps.isDigital,
      isPendant: customProps.isPendant,
      pendantMaterial: customProps.pendantMaterial,
      layout: layouts[customProps.sizeId][customProps.orientation],
      theme: themes[customProps.themeId],
      mapLoaded: false,
    }));
  };

  setShouldGetCanvasImage = (value: boolean): void => {
    this.setState({
      shouldGetCanvasImage: value,
    });
  };

  setShouldSaveDesign = (value): void => {
    this.setState({
      shouldSaveDesign: value,
    });
  };

  setShouldDropAHint = (value: string): void => {
    this.setState({
      shouldDropAHint: value,
    });
  };

  setCanvasImageUrl = (previewImageUrl: string | null): void => {
    this.setState(() => ({
      previewImageUrl,
    }));
  };

  setShortLocationText = (shortLocationText: string | null): void => {
    this.setState(() => ({
      shortLocationText,
    }));
  };

  setMapLoaded = (value: boolean): void => {
    this.setState({
      mapLoaded: value,
    });
  };

  setMapRef = (map): void => {
    this.setState({
      mapRef: map,
    });
  };

  setFrame = (frame: string): void => {
    let framed = false;
    let frameColor;
    if (frame) {
      framed = true;
      frameColor = frame;
    }
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        isFramed: framed,
        frameColor,
      },
    }));
  };

  setCanvas = (canvas: boolean): void => {
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        isCanvas: canvas,
      },
    }));
  };

  setDigital = (digital: boolean): void => {
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        isDigital: digital,
      },
    }));
  };

  setPendant = ({ isPendant }: { isPendant: boolean }): void => {
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        isPendant,
        pendantMaterial: customProps.pendantMaterial && customProps.pendantMaterial !== "" ? customProps.pendantMaterial : "silver",
      },
    }));
  };

  formatUpdated = (format: string): void => {
    ga4Analytics.logEventDesignFormat(format);
  };

  updateShape = (shape: string): boolean => {
    // Set defaults
    const { originalProps } = this.state;
    const sizesForCountry = {
      landscape: originalProps.customProps.sizeId,
      // portrait and landscape share size ids
      portrait: originalProps.customProps.sizeId,
      square: "16x16",
    };

    // get current orientation/size
    const { customProps, landscapePortraitSize } = this.state;
    const { orientation, sizeId } = customProps;

    // do we need to switch?
    if (orientation === shape) {
      // no change - return
      return false;
    }

    // Current values, to overwrite
    let updateToSizeId;
    let updateToOrientation;
    switch (shape) {
      case "square": {
        // Change from landscape/portrait to square

        // Save State for if we revert out of square
        this.setState({ landscapePortraitSize: `${sizeId}` });

        // switch to square
        updateToSizeId = sizesForCountry.square;
        updateToOrientation = shape; // = "square"
        break;
      }
      case "portrait": {
        // Change from landscape/square to portrait

        // if currently set to portrait
        if (orientation === "landscape") {
          // we're just changing orientation
          updateToSizeId = sizeId;
          updateToOrientation = shape; // = "portrait"
        } else {
          // change from square to portrait - try to use last size
          updateToSizeId = landscapePortraitSize || sizesForCountry.portrait;
          updateToOrientation = shape; // = "portrait"
        }

        break;
      }
      case "landscape": {
        // Change from portrait/square to landscape

        // if currently set to portrait
        if (orientation === "portrait") {
          // we're just changing orientation
          updateToSizeId = sizeId;
          updateToOrientation = shape; // = "landscape"
        } else {
          // change from square to portrait - try to use last size
          updateToSizeId = landscapePortraitSize || sizesForCountry.landscape;
          updateToOrientation = shape; // = "landscape"
        }

        break;
      }
      default: {
        // shape unrecognised
        console.error(`Shape unrecognized: ${shape}`);
        return false;
      }
    }

    if (!updateToSizeId || !updateToOrientation) {
      console.error(`Missing size id (${updateToSizeId}) or orientation: ${updateToOrientation} during shape change`);
      return false;
    }

    ga4Analytics.logEventDesignLayout(updateToOrientation);
    ga4Analytics.logEventDesignSize(updateToSizeId);

    // Get layout and apply update to state
    const { layouts } = this.state;
    const layout = layouts[updateToSizeId][updateToOrientation];
    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        sizeId: updateToSizeId,
        orientation: updateToOrientation,
      },
      layout,
    }));

    return true;
  };

  toggleWhite = (): void => {
    const { customProps } = this.state;
    ga4Analytics.logEventThemeOptionOnWhite(!customProps.white);

    const oldWhite = customProps.white;

    let themeIdString;
    if (oldWhite) {
      themeIdString = customProps.themeId.replace(`White`, ``);
    } else {
      themeIdString = customProps.themeId;
    }

    this.setState(
      {
        customProps: { ...customProps, white: !oldWhite },
      },
      () => {
        this.changeMapTheme(themeIdString);
      },
    );

    // Flag Edit Action
    this.notifyDesignEdited();
  };

  toggleConstellations = (): void => {
    const { customProps: props } = this.state;
    ga4Analytics.logEventThemeOptionConstallations(!props.constellations);

    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        constellations: !customProps.constellations,
      },
    }));

    // Set pendant to first page
    this.setPendantImageCarouselSelected(0);

    // Flag Edit Action
    this.notifyDesignEdited();
  };

  toggleGrid = (): void => {
    const { customProps: props } = this.state;
    ga4Analytics.logEventThemeOptionGrid(!props.grid);

    this.setState(({ customProps }) => ({
      customProps: { ...customProps, grid: !customProps.grid },
    }));

    // Set pendant to first page
    this.setPendantImageCarouselSelected(0);

    // Flag Edit Action
    this.notifyDesignEdited();
  };

  toggleMoon = (): void => {
    const { customProps: props } = this.state;
    ga4Analytics.logEventThemeOptionMoon(!props.moon);
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, moon: !customProps.moon },
    }));

    // Flag Edit Action
    this.notifyDesignEdited();
  };

  toggleLabels = (): void => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, constellationLabels: !customProps.constellationLabels },
    }));

    // Flag Edit Action
    this.notifyDesignEdited();
  };

  toggleShowTime = (): void => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, showTime: !customProps.showTime },
    }));

    // Flag Edit Action
    this.notifyDesignEdited();
  };

  updateSizeText = (): void => {
    // Update map size:
    const { priceList, customProps } = this.state;
    const { orientation, sizeId } = customProps;

    const sizeText = sizeId;

    // Make sure a valid text size is available
    if (
      !priceList ||
      !priceList.pricing ||
      !priceList.pricing[orientation] ||
      !priceList.pricing[orientation][sizeId] ||
      priceList.pricing[orientation][sizeId].sizeText === ""
    ) {
      // try to build from size id
      if (!sizeId || sizeId === "") {
        return this.setState({ sizeText });
      }

      const parts = sizeId.toLowerCase().split(`x`);
      const xSize = parts[0].trim();
      const ySize = parts[1].trim();

      if (orientation === "landscape") {
        return this.setState({ sizeText: `${ySize} x ${xSize}"` });
      }

      return this.setState({ sizeText: `${xSize} x ${ySize}"` });
    }

    return this.setState({ sizeText: priceList.pricing[orientation][sizeId].sizeText });
  };

  updateSize = (size: string): void => {
    // Log Location Update
    ga4Analytics.logEventDesignSize(size);

    this.setState(({ customProps }) => ({
      customProps: {
        ...customProps,
        sizeId: size,
        sizeSelected: size,
      },
    }));
  };

  updatePendantMaterial = (material: string): void => {
    this.setState(({ customProps }) => ({
      customProps: { ...customProps, pendantMaterial: material },
    }));
  };

  notifyDesignEdited = () => {
    this.setState(({ designEdited }) => ({
      designEdited: designEdited + 1,
    }));
  };

  cheapestPrintPriceForUpsell = () => {
    try {
      const { priceList } = this.state;

      // Get the price of our cheapest print:
      if (
        priceList &&
        priceList.pricing &&
        priceList.pricing.square &&
        priceList.pricing.square["12x12"] &&
        priceList.pricing.square["12x12"].print &&
        priceList.pricing.square["12x12"].print.customer &&
        priceList.pricing.square["12x12"].print.customer.gross &&
        priceList.pricing.square["12x12"].print.customer.currency
      ) {
        return pricingUtils.formatMoney(
          priceList.pricing.square["12x12"].print.customer.gross,
          priceList.pricing.square["12x12"].print.customer.currency,
        );
      }
    } catch (error) {
      console.error(error);
    }

    return "";
  };

  render(): JSX.Element {
    return (
      <TnsPrintPropsContextProvider value={this.state} {...this.props}>
        {this.props.children}
      </TnsPrintPropsContextProvider>
    );
  }
}

export default TnsStarmapPropsProvider;
