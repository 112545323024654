import FontFaceObserver from "fontfaceobserver";

const Fonts = () => {
  const latoLink = document.createElement("link");
  latoLink.href = "https://fonts.googleapis.com/css?family=Lato&display=optional";
  latoLink.rel = "stylesheet";
  const robotoLink = document.createElement("link");
  robotoLink.href = "https://fonts.googleapis.com/css?family=Roboto&display=optional";
  robotoLink.rel = "stylesheet";

  document.head.appendChild(latoLink);
  document.head.appendChild(robotoLink);

  const lato = new FontFaceObserver("Lato");
  const roboto = new FontFaceObserver("Roboto");

  lato.load().then(() => {
    document.documentElement.classList.add("lato");
  });
  roboto.load().then(() => {
    document.documentElement.classList.add("roboto");
  });
};

export default Fonts;
